<template>
  <v-row
    @keydown.ctrl.90="clear"
    no-gutters
    ref="container"
    class="wrapper"
    justify="center"
  >
    <canvas ref="signaturePad" :width="w" :height="h"></canvas>
    <v-sheet class="background" :width="w" :height="h" ref="signaturePadBackground">
			<v-icon color="#bebebe" x-large >mdi-draw</v-icon>
		</v-sheet>
  </v-row>
</template>

<script>
import signature_pad from "signature_pad";
import { decode64 } from "@/utils/index.js";
export default {
  props: ["bus"],
  data() {
    return {
      pad: null,
			h: null,
			w: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      let w = this.$refs.container.clientWidth;
      let h = this.$refs.container.clientHeight;
      this.w = w <= 800 ? w-2 : 800;
      this.h = h <= 400 ? h-1 : 400;

      this.pad = new signature_pad(this.$refs.signaturePad, {
        minWidth: 2,
        maxWidth: 5,
        penColor: this.$vuetify.theme.themes.light.secondary,
      });
    });

    this.bus.$on("save", () => {
      this.save();
    });
    this.bus.$on("clear", () => {
      this.clear();
    });
  },
  methods: {
    save() {
      if (this.pad.isEmpty()) return;
      let svg = this.pad.toDataURL("image/svg+xml");
      let decodedSvg = atob(svg.substring(26)).replaceAll(
        /stroke=\"#([A-Z0-9])+\"/g,
        'stroke="#000"'
      ).replaceAll(
        /fill=\"#([A-Z0-9])+\"/g,
        'fill="#000"'
      );
      svg = "data:image/svg+xml;base64," + btoa(decodedSvg);
      this.$emit("firmaGuardada", svg);
    },
    clear() {
      this.pad.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: start;
  canvas {
    // border: 1px solid black;
		box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
		// background: #f1f1f1;
    margin-top: 10px;
		z-index: 1;
  }
	.background {
		background: #fafafa;
		position: absolute;
    margin-top: 10px;
		pointer-events: none;
		display: grid;
	}
}
</style>